<template>
  <div class="clientsList">

    <mini-loader v-if="load" />
    <div class="card" style="overflow-x: auto; max-width: 100%">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="text-dark">Все шаблоны</h5>
        <a @click="createItem">
          <span class=" p-1">
            <button type="button" class="btn btn-success" >Добавить
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>

          </span>
        </a>
      </div>
      <table class="table table-striped" id="addressesList">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th>Тип договора</th>
            <th>Кто может скачать договор</th>
            <th>Загрузить</th>
            <th scope="col">Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in data">
            <th scope="row">{{index+1}}</th>
            <td> {{value.title}} </td>
            <td> <span v-if="value.visible == 'for_all'" style="color: blue">Все</span> <span v-else style="color: red">Только админы</span> </td>
            <td> <a :href="$store.state.urlBackend + value.template">Скачать</a> </td>
            <td style="display: flex">
              <a  @click="showItem(value)">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
                </span>
              </a>
              <a @click="editItem(value)">
                <span class="border rounded border-success m-1 p-2 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </span>
              </a>
              <a @click="deleteItem(index, value)">
                <span class="border rounded border-danger m-1 p-2 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
    </div>

    <!-- Show modal -->
    <!-- <a-modal v-model="visibleShowModal" footer="">
      <div class="">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td>Клиент: </td>
              <td><span v-if="item.client" style="color: #5355D3; cursor: pointer">{{item.client.name}}</span> <span v-else></span></td>
            </tr>
            <tr>
              <td>ФИО руководителя</td>
              <td>{{item.client_name}}</td>
            </tr>
            <tr>
              <td>Дата договора</td>
              <td>{{item.registration_date}}</td>
            </tr>

            <tr>
              <td>Номер договора</td>
              <td>{{item.title}}</td>
            </tr>

            <tr>
              <td>Адрес клиента</td>
              <td>{{item.address}}</td>
            </tr>

            <tr>
              <td>Телефон</td>
              <td>{{item.phone}}</td>
            </tr>

            <tr>
              <td>МФО</td>
              <td>{{item.bank_code}}</td>
            </tr>

            <tr>
              <td>Банк</td>
              <td>{{item.bank}}</td>
            </tr>

            <tr>
              <td>Расчетный счет</td>
              <td>{{item.checking_account}}</td>
            </tr>

            <tr>
              <td>Дата договора</td>
              <td>{{item.registration_date}}</td>
            </tr>

            <tr>
              <td>ИНН клиента</td>
              <td>{{item.tin}}</td>
            </tr>

            <tr>
              <td>ОКЕД</td>
              <td>{{item.oked}}</td>
            </tr>

            <tr>
              <td>Код НДС</td>
              <td>{{item.vat_code}}</td>
            </tr>

            <tr>
              <td>Сотрудник</td>
              <td><span v-if="item.employee" style="color: #5355D3; cursor: pointer">{{item.employee.name}}</span> <span v-else></span></td>
            </tr>
            <tr>
              <td>Создатель договора</td>
              <td><span v-if="item.creator" style="color: #5355D3; cursor: pointer">{{item.creator.name}}</span> <span v-else></span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </a-modal> -->

    <!-- Create modal -->
    <a-modal v-model="visibleCreateModal" title="Добавить client', 'creator', 'employee', 'filesа"  footer="" class="address-create">
      <div class="mb-3">
        <label for="">Наименование договора (вид договора) <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Наименование шаблона" v-model="newItem.title"  class="form-control" :class="{'is-invalid': validateErrors.title}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Кому видно? <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Выберите опцию"
            style="width: 100%"
            v-model="newItem.visible"
            optionLabelProp="label"
            optionFilterProp="label"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.visible}"
          >
          <a-select-option v-for="type in typeOfVisibles" :key="type.value" :value="type.value" :label="type.title">
            {{type.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.visible"> {{value}} </div>
      </div>
      <div :class="{'is-invalid': validateErrors.template}" class="mb-3">
        <div v-if="!file">
            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
              <div class="dropZone-info" @drag="onChange" >
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title">Перетащите файл сюда</span>
                <div class="dropZone-upload-limit-info">
                  <div>Поддерживаемые расширения: docx, doc</div>
                  <div>Максимальный размер файла: 8МБ</div>
                </div>
              </div>
              <input type="file" @change="onChange">
            </div>
          </div>
          <div v-else class="dropZone-uploaded">
            <div class="dropZone-uploaded-info">
              <span class="dropZone-title">Uploaded</span>
              <button type="button" class="btn btn-primary removeFile" @click="removeFile">Remove File</button>
            </div>
          </div>
      </div>
      <div class="invalid-feedback" v-for="value in validateErrors.template"> {{value}} </div>
      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="storeItem">Добавить</button>
      </div>
    </a-modal>

    <!-- Edit modal -->
    <a-modal v-model="visibleEditModal" title="Изменить адреса"  footer="">
      <div class="mb-3">
        <label for="">Наименование договора (вид договора) <span style="color: #e55353">*</span></label>
        <template>
          <a-input placeholder="Номер договора" v-model="item.title"  class="form-control" :class="{'is-invalid': validateErrors.title}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
        </template>
      </div>
      <div class="mb-3">
        <label for="">Кому видно? <span style="color: #e55353">*</span></label>
        <a-select
            placeholder="Выберите опцию"
            style="width: 100%"
            v-model="item.visible"
            optionLabelProp="label"
            optionFilterProp="label"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.visible}"
          >
          <a-select-option v-for="type in typeOfVisibles" :key="type.value" :value="type.value" :label="type.title">
            {{type.title}}
          </a-select-option>
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.visible"> {{value}} </div>
      </div>
      <template>
        <a-upload-dragger
          :action="$store.state.urlBackend + '/api/clients/contracts/templates/file/upload/' + item.id"
          :headers='{"Authorization": `Bearer ` + access_token}'
          name="template"
          :multiple="false"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">
            Перетащите файл сюда
          </p>
          <p class="ant-upload-hint">
            <div>Поддерживаемые расширения: docx, doc</div>
            <div>Максимальный размер файла: 8МБ</div>
          </p>
        </a-upload-dragger>
      </template>



      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="updateItem">Сохранить</button>
      </div>
    </a-modal>

    <!-- Delete modal -->
    <a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
      <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
    </a-modal>

  </div>
</template>

<script>
import axios from 'axios'
import moment, { Moment } from 'moment';
import { defineComponent, ref } from 'vue';
const data = '';
const dateFormat = 'DD-MM-YYYY';
export default {
  name: "ContractTemplates",
  data() {
    return {
      index: null,
      data,
      item: [],
      file: '',
      access_token: localStorage.token,
      dragging: false,
      newItem: {
        title: '',
        visible: '',
        template: ''
      },
      oldItem: {
        title: '',
        visible: '',
        template: ''
      },
      dataFilter: {
        clients: [],
        employees: [],
        creators: [],
        search: '',
        page: 1
      },
      validateErrors: [],

      countries: [{id: 192, title: 'Узбекистан'}],
      regions: [],
      cities: [],
      typeOfVisibles: [{title: "Всем", value: 'for_all'},{title: "Админам", value: 'for_admin'}],
      clients: [],
      employees: [],

      index: '',
      currentPage: 1,
      totalResults: 0,
      scrolled: false,
      old_height: 0,
      last_page: 1,

      load: true,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleCreateModal: false,
      visibleDeleteModal: false,

    }
  },
  mounted(){
    this.getAllContractTemplates();

    window.onscroll = () => {
      let height = document.getElementById('addressesList').clientHeight
      if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
        if(this.old_height!=height){
          this.old_height = height;
          let limit = this.currentPage + 1;
          if(limit <= this.last_page){
            this.dataFilter.page = limit;
            this.addContractTemplates();
          }
        }
      }
    };

    this.newItem.employee_id = this.me.id;
  },
  methods: {
    handleChange(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} файл успешно загружен.`);
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} не корректный файл.`);
      }
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files[0]);
    },
    createFile(file) {

      if (file.size > 8182000) {
        this.$message.error(
          'Пожалуйста выберите файла размером меньше 8МБ',
          3
        )
        this.dragging = false;
        return;
      }
      this.file = file;
      this.newItem.template = file;
      this.dragging = false;
    },
    removeFile() {
      this.file = '';
    },


    addContractTemplates(){
      this.load = true;
      axios.get('/clients/contracts/templates/all', {params: this.dataFilter }).then((response) => {
        if(response.status == 200){
          this.data = this.data.concat(response.data.data);
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
          this.currentPage = response.data.current_page;
        }else if(response.status == 204){
          this.$message.error(
            "Нет соответствующих шаблонов",
            3
          )
        }
      }).catch((error) => {

      }).finally(()=>{
        this.load = false;
      });
    },
    getAllContractTemplates(){
      this.dataFilter.page = 1;
      this.old_height = 0;
      axios.get('/clients/contracts/templates/all', {params: this.dataFilter }).then((response) => {
        if(response.status == 200){
          this.data = response.data.data;
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
          this.currentPage = response.data.current_page;
        }else if(response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.last_page = 0;
          this.currentPage = 0;
          this.$message.error(
            "Нет соответствующих шаблонов",
            3
          )
        }
      }).catch((error) => {

      }).finally(()=>{
        this.load = false;
      });
    },

    createItem(){
      this.visibleCreateModal = true;
    },
    editItem(value){
      this.visibleEditModal = true;
      this.item = value;
      this.validateErrors = [];
      this.$forceUpdate();
    },
    showItem(value){
      this.visibleShowModal = true;
      this.item = value;
    },
    deleteItem(index, value){
      this.item = value;
      this.index = index;
      this.visibleDeleteModal = true;
    },

    storeItem(){
      let formData = new FormData();
      formData.append('template', this.file);
      formData.append('title', this.newItem.title);
      formData.append('visible', this.newItem.visible);

        this.load = true;
        axios.post('/clients/contracts/templates', formData, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': `multipart/form-data`,
          }}).then((response) => {
          if(response.status == 201){
            this.data = this.data.concat(response.data);
            this.totalResults++;
            this.newItem = this.oldItem;
            this.validateErrors = [];
            this.visibleCreateModal = false;
            this.$message.success(
              "Успешно добавлено",
              3
            );

          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              error.response.data.message,
              3
            );
            this.validateErrors = error.response.data.errors
          }else if(error.response.status == 404){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {
          this.load = false;
        })
    },
    updateItem(){
      this.load = true;
      axios.put('/clients/contracts/templates/'+this.item.id, this.item).then((response) => {
        if(response.status == 200){
          this.$message.success(
            "Успешно изменено",
            3
          );
          this.item = response.data;
          console.log(response.data);
          this.validateErrors = [];
          this.visibleEditModal = false;
        }
      }).catch((error) => {
        if(error.response.status == 422){
          this.$message.error(
            error.response.data.message,
            3
          );
          this.validateErrors = error.response.data.errors;
        }else if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          )
        }else if(error.response.status == 500){
          this.$message.error(
            "Ошибка сервера",
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },
    destroyItem(){
      this.load = true;
      axios.delete('/clients/contracts/templates/'+this.item.id).then((response) => {
        if(response.status == 200){
          this.$message.success(
            response.data.success,
            3
          );
          this.data.splice(this.index, 1)
          this.totalResults--;
          this.visibleDeleteModal = false;
        }
      }).catch((error) => {
        if(error.response.status == 404){
          this.$message.error(
            error.response.data.error,
            3
          );
        }else if(error.response.status == 500){
          this.$message.error(
            "Ошибка сервера",
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },

    exportToWord(value){
      this.load = true;
      // axios.post('/clients/contracts/templates/export-to-word/'+value.id, {headers: {"Access-Control-Allow-Origin": "*"}}).then((response)  =>  {
      //   if(response.status == 200){
      //     this.$message.success(
      //       'Успешно экспортировано',
      //       3
      //     );
      //     console.log(response.data)
      //   }
      // }).catch((error)  =>  {
      //   console.log(error)
      // });

      axios.post('/clients/contracts/templates/export-to-word/' + value.id, {headers: {'Access-Control-Allow-Origin': '*','Content-Disposition': 'form-data'}}).then((response) => {

        if(response.status == 200){
          console.log(response.data);
          window.location.href = this.$store.state.urlBackend + response.data.url;
          // const blob = new Blob([response.data], { type:'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
          // const downloadURL = window.URL.createObjectURL(blob);
          // console.log(downloadURL);
          // const link = document.createElement('a');
          // link.href = downloadURL;
          // link.download = 'data.docx';
          // link.click();
        }
      }).catch((error) => {

      }).finally(() => {this.load = false});
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    changeRegistrationDate(date, dateString){
      this.newItem.registration_date = dateString;
      console.log(dateString);
    },
    changeRegistrationDateEditForm(date, dateString){
      this.item.registration_date = dateString;
      console.log(dateString);
    },

    applyFilter(){
      this.load = true;
      this.getAllContractTemplates();
    },
    applyFilterAutocomplete(){
      if(this.itemName.length > 1 || this.itemName.length == 0){
        this.old_height = 0;
        this.load = true;
        axios.post('/branches/international/autocomplete/search/with/paginate', this.dataFilter, {params: {query: this.itemName}})
        .then(response => {
          if(response.status == 200){
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.load = false;
            this.last_page = response.data.last_page;
          }else if(response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.load = false;
            this.$message.error(
              'Офисов не найдено',
              3
            );
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
            this.load = false;
          }else if(error.response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.load = false;
            this.$message.error(
              'Офисов не найдено',
              3
            );
          }else{
            this.load = false
          }
        });
      }
    },
  },
  computed: {
    me () {
      return JSON.parse(localStorage.me)
    }
  },
  watch: {
    "visibleMap": function(value){
      if(value){
        setTimeout(() => {
          this.driving = "DRIVING";
        }, 1000);
      }else{
        this.driving = "WALKING";
      }
    },
  }
}
</script>

<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
  .address-create .ant-modal{
    width: 600px !important;
    height: 100%;
    top: 10px;
  }
  .map__modal .ant-modal{
    width: 1000px !important;
    height: 500px !important;
    top: 10px;
  }

  .my-img-circle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .my-img-circle img{
    width: 75px;
  }




  .dropZone {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px dotted #2e94c4;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #5C5C5C;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 100%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }
  .ant-calendar-picker-container{
    z-index: 2030 !important;
  }
  .removeFile {
    width: 200px;
  }
</style>
